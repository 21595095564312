import { SPONSOR_BASE_FRAGMENT } from '@/graphql/_Fragments/Sponsor/Base';
import { SESSION_BASE_FRAGMENT } from '@/graphql/_Fragments/Session/Base';

export const SPONSOR_ROLE_ENTITY_LIST_FRAGMENT = `
  fragment sponsorRoleEntityListFragment on SponsorRole {
    id
    uid
    __typename
    schemaCode
    role
    sponsor {
      ...sponsorBaseFragment
    }
    session {
      ...sessionBaseFragment
    }
  }
  ${SESSION_BASE_FRAGMENT}
  ${SPONSOR_BASE_FRAGMENT}
`;
